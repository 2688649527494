import logo from './logo.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <div className='message'>Big Improvements Coming! <br/>Our website is moving to a new server for a better experience.<br/>Check back soon!</div>
        
      </header>
    </div>
  );
}

export default App;
